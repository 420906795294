import React from 'react'

import ChartTabs from './ChartTabs'
import ChartControls from './ChartControls'
import CompareChartAnalyte from './CompareChartAnalyte'
import AnalyteDropdown from './chart/AnalyteDropdown'
import CompareChartPressure from './CompareChartPressure'
import MeasureDropdownDetails from './chart/MeasureDropdownDetails'
import GroupingsDropdownDetails from './chart/GroupingsDropdownDetails'

const ChartSection = () => (
    <div>
        <ChartTabs />
        <ChartControls />
        <AnalyteDropdown />
        <CompareChartAnalyte />
        
        <MeasureDropdownDetails />
        <GroupingsDropdownDetails />
        <CompareChartPressure />
    </div>
)

export default ChartSection