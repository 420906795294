import React from 'react'

const CustomLegend = ({ legendPayload }) => {
    return (
        <ul className="recharts-default-legend" style={{ textAlign: 'center' }}>
            {legendPayload.map((item, index) => (
                <li
                    key={`line-legend-${index}`}
                    className={`recharts-legend-item legend-item-${index}`}
                    style={{ display: 'inline-block', marginRight: '10px' }}
                >
                    <svg
                        className="recharts-surface"
                        width="14"
                        height="14"
                        viewBox="0 0 32 32"
                        version="1.1"
                        style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginRight: '4px',
                        }}
                    >
                        <path
                            stroke="none"
                            fill={item.color}
                            d="M0,4h32v24h-32z"
                            className="recharts-legend-icon"
                        ></path>
                    </svg>
                    <span className="recharts-legend-item-text">
                        {item.value}
                    </span>
                </li>
            ))}
        </ul>
    )
}

export default CustomLegend