import React, { useContext } from 'react'
import Draggable from 'react-draggable'

import { AppStateContext } from '../AppStateContext'
import Panel from '../../../elem/panel/Panel'
import WellDetailPanelHeader from './WellDetailPanelHeader'
import DataContextProvider from './DataContext'
import DetailTabs from './DetailTabs'
import WellDetails from './WellDetails'
import SampleDetails from './SampleDetailList'
import MITDetails from './MITDetailList'
import { detailPanel as panelClassConfig } from '../../../../utils/styles/panelStyles'
import PressureDetail from './PressureDetailList'
import DetailChart from './chart/DetailChart'
import ScreenCasingDetailList from './ScreenCasingDetailList'
import ZoneDetailList from './ZoneDetailList'
import ProjectDetailList from './ProjectDetailList'

const Detail = () => {
    const { detailState } = useContext(AppStateContext)
    const visible = detailState.well.visible
    return (
        <Draggable bounds="parent" handle=".wellDetailPanelHeader" positionOffset={{x: '.75rem', y: '.75rem'}} >
            <div className={`column is-4-desktop is-half-tablet is-full-mobile detailPanelWrapper ${!visible ? 'is-invisible' : '' }`}>
                <Panel panelClassConfig={panelClassConfig()}>
                    <DataContextProvider>
                        <WellDetailPanelHeader />
                        <div className="wellDetailWrapper">
                            <DetailChart />
                            <DetailTabs />
                            <WellDetails />
                            <ProjectDetailList />
                            <ScreenCasingDetailList />
                            <SampleDetails />
                            <PressureDetail />
                            <MITDetails />
                            <ZoneDetailList />
                        </div>
                    </DataContextProvider>
                </Panel>
            </div>
        </Draggable>
    )
}

export default Detail
