import React, { useContext } from 'react'
import Draggable from 'react-draggable'

import { AppStateContext } from '../AppStateContext'
import Panel from '../../../elem/panel/Panel'
import DataContextProvider from './DataContext'
import { comparePanel as panelClassConfig } from '../../../../utils/styles/panelStyles'
import CompareMenuPanelHeader from './CompareMenuPanelHeader'
import CompareTableFacility from './CompareTableFacility'
import ChartSection from './ChartSection'

const Detail = () => {
    const { compareMenuVisible } = useContext(AppStateContext)
    return (
        <Draggable bounds="parent" handle=".wellDetailPanelHeader" positionOffset={{x: '.75rem', y: '.75rem'}} >
            <div className={`column is-4-desktop is-half-tablet is-full-mobile detailPanelWrapper ${!compareMenuVisible ? 'is-invisible' : '' }`}>
                <Panel panelClassConfig={panelClassConfig()}>
                    <DataContextProvider>
                        <CompareMenuPanelHeader />
                        <div className="wellDetailWrapper">
                            <ChartSection />
                            <CompareTableFacility />
                        </div>
                    </DataContextProvider>
                </Panel>
            </div>
        </Draggable>
    )
}

export default Detail
