import React, {
    useContext,
    useEffect,
    useMemo,
    useState,
    useCallback,
} from 'react'
import {
    ComposedChart,
    Line,
    YAxis,
    XAxis,
    Tooltip,
    Label,
    CartesianGrid,
    ResponsiveContainer,
    ReferenceArea,
    Legend,
} from 'recharts'

import { DataContext } from './DataContext'
import Message from '../../../elem/chart/Message'
import Spinner from '../../../elem/Spinner'
import ChartTooltip from '../../../elem/chart/Tooltip'
import { dateToString } from '../../../../utils/dateUtils'
import { AppStateContext } from '../AppStateContext'
import withConfig from '../../../wrappers/withConfig'
import dayjs from 'dayjs'
import palette from '../../../../utils/chart/palette'
import { getAxisYDomain } from '../../../../utils/chart/slice'
import { roundToFourDigits } from '../../../../utils/chart/values'
import CustomLegend from './chart/CustomLegend'

export default withConfig(({ config }) => {
    const {
        analyteChartData,
        zoomTrigger,
        selectedAnalytes,
        isLoadingChart,
        visibleChartTab,
        selectedUnits,
    } = useContext(DataContext)
    const {
        mapState: { selectedFeatures },
    } = useContext(AppStateContext)

    const dateField = 'SampleDate'
    const keyField = 'FacilityID'
    const facilityName = 'Sampling Point'
    const [data, setData] = useState([])

    const [left, setLeft] = useState('dataMin')
    const [right, setRight] = useState('dataMax')
    const [refAreaLeft, setRefAreaLeft] = useState('')
    const [refAreaRight, setRefAreaRight] = useState('')
    const [top, setTop] = useState('dataMax')
    const [bottom, setBottom] = useState('dataMin')

    const zoom = useCallback(() => {
        let RAL = refAreaLeft
        let RAR = refAreaRight
        if (RAL === RAR || RAR === '') {
            RAL = ''
            RAL = ''
            return
        }

        // xAxis domain
        if (RAL > RAR) {
            const temp = RAL
            RAL = RAR
            RAR = temp
        }

        // yAxis domain
        const leftAxisDomain = getAxisYDomain(RAL, RAR, (x) => !x.includes(dateField), 0, data, dateField)
        setBottom(leftAxisDomain[0])
        setTop(leftAxisDomain[1])

        setLeft(RAL)
        setRight(RAR)
        setRefAreaRight('')
        setRefAreaLeft('')
    }, [refAreaLeft, refAreaRight, data])

    const zoomOut = useCallback(() => {
        setRefAreaLeft('')
        setRefAreaRight('')
        setLeft('dataMin')
        setRight('dataMax')
        setTop('dataMax')
        setBottom('dataMin')
    }, [data])

    useEffect(() => {
        zoomOut()
    }, [zoomTrigger])

    const uniqueSeries = useMemo(
        () =>
            [
                ...new Set(
                    analyteChartData
                        .map(x => x[keyField])
                ),
            ]
                .sort()
                .map((x, idx) => ({
                    name: x,
                    color: palette[idx % palette.length],
                })),
        [analyteChartData]
    )

    const seriesData = useMemo(() => {
        const analyteFilteredChartData = 
            analyteChartData.filter(orig => selectedAnalytes.find(x => x && x.ParamID ? x.ParamID === orig.ParamID : true))

        if (analyteFilteredChartData && analyteFilteredChartData.length) {
            const dates = [
                ...new Set(
                    analyteFilteredChartData.map(x => x[dateField])
                ),
            ]
            const d = dates.reduce((x, c) => {
                const entry = uniqueSeries.reduce((acc, curr) => {
                    const matchDate = analyteFilteredChartData.find(w => w[dateField] === c && w[keyField] === curr.name)
                    if (matchDate) {
                        return {
                            ...acc,
                            [curr.name]: matchDate['Concentration']

                        }
                    } else {
                        return acc
                    }
                }, {[dateField]: c})

                return [
                    ...x,
                    entry
                ]
            }, [])
            return d
        }
        return analyteFilteredChartData
    }, [analyteChartData, uniqueSeries, selectedAnalytes])

    useEffect(() => {
        if (seriesData && seriesData.length) {
            const d = seriesData.map(x => ({
                ...x,
                [dateField]: dayjs(x[dateField])
                    .toDate()
                    .getTime(),
            }))
            setData(d)
        } else {
            setData([])
        }
    }, [seriesData])

    const legendData = useMemo(
        () =>
            uniqueSeries &&
            uniqueSeries.map(({ name, color }) => ({
                color,
                type: 'line',
                dataKey: name,
                value: name,
            })),
        [uniqueSeries]
    )

    // if loading || no data, return nothing
    if (visibleChartTab !== 'sampleResult') {
        return null
    }
    if (isLoadingChart) {
        return <Spinner extraClass="chartWrapper" />
    }
    if (!data.length) {
        return <Message text={`No Chart Data Available`} />
    }

    // // construct chart title
    // const chartTitle = `RBDMS ID # ${RBDMSID}`

    // construct chart
    const yAxisLabel = `Concentration (${
        selectedUnits ? selectedUnits : 'units unknown'
    })`
    const xAxisLabel = 'Sample Date'

    // shared chart props
    const animationDuration = 200

    const selectedAnalyteDisplay = selectedAnalytes[0] && selectedAnalytes[0].Analyte
    const lines = uniqueSeries.map(({ name, color }, idx) => (
        <Line
            key={`time-series-${name}`}
            type="monotone"
            dataKey={name}
            name={name}
            unit={selectedUnits}
            stroke={color}
            connectNulls 
            strokeWidth={1}
            dot={{ r: 1, fill: color, stroke: color }}
            activeDot={{ r: 5, fill: color, stroke: color }}
            animationDuration={animationDuration}
            isAnimationActive={false} 
        />
    ))

    return (
        <div className="chart">
            <div className="chartTitle">{selectedAnalyteDisplay}</div>
            <div className="chartWrapper">
                <ResponsiveContainer width="100%" minHeight={'250px'}>
                    <ComposedChart
                        data={
                            data
                                .sort((a, b) => a.SampleDate - b.SampleDate)
                                .slice()
                        }
                        margin={{
                            top: 10,
                            right: 50,
                            left: 12,
                            bottom: 15,
                        }}
                        onMouseDown={e => e && e.activeLabel && setRefAreaLeft(e.activeLabel)}
                        onMouseMove={e => e && e.activeLabel && refAreaLeft && setRefAreaRight(e.activeLabel)}
                        // eslint-disable-next-line react/jsx-no-bind
                        onMouseUp={() => zoom()}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            allowDataOverflow
                            type="number"
                            dataKey={dateField}
                            domain={[left, right]}
                            tickFormatter={unixTime => dateToString(unixTime)}
                        >
                            <Label
                                value={xAxisLabel}
                                offset={-10}
                                position="insideBottom"
                                className="nitrateChartXAxisLabel"
                            />
                        </XAxis>
                        <YAxis 
                            interval='preserveStartEnd'
                            // allowDataOverflow 
                            type="number" 
                            domain={[bottom, top]} 
                            tickFormatter={v => roundToFourDigits(v)} 
                            yAxisId={0}
                        >
                            <Label
                                value={yAxisLabel}
                                angle={-90}
                                offset={20}
                                position="insideLeft"
                                className="detailChartYAxisLabel"
                            />
                        </YAxis>
                        <Tooltip content={<ChartTooltip stagger={90}/>} allowEscapeViewBox={{x: true, y: true}}/>
                        <Legend
                            content={
                                <CustomLegend legendPayload={legendData} />
                            }
                            verticalAlign="bottom"
                            wrapperStyle={{
                                bottom: `0`,
                                left: 0,
                                alignItems: 'center',
                                width: '100%',
                            }}
                        />
                        {lines}
                        {/* {dots} */}
                        {refAreaLeft && refAreaRight ? (
                            <ReferenceArea
                                yAxisId={0}
                                x1={refAreaLeft}
                                x2={refAreaRight}
                                strokeOpacity={0.3}
                            />
                        ) : null}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
})