import React, { useContext } from 'react'

import { DataContext } from './DataContext'
import NoDataComponent from '../../../elem/table/client/NoDataComponent'
import TableContent from '../../../elem/table/client/TableContent'
import parseColumns from '../../../../utils/table/parseColumns'
import withConfig from '../../../wrappers/withConfig'

export default withConfig(({ config }) => {
    const { compareListData, configData, isLoading } = useContext(DataContext)
    const { ID_COLUMN, EXPLORER_LINK_COLUMN } = config
    if (
            compareListData &&
            configData
    ) {
        const columns = parseColumns(compareListData, configData, EXPLORER_LINK_COLUMN, ID_COLUMN)
        return (
            <div className="is-size-7 sampleDetailWrapper">
                <NoDataComponent data={configData} isLoading={isLoading}/>
                <TableContent
                    name="compareTable"
                    columns={columns}
                    data={compareListData}
                    containerClass={'detailTableWrapper'}
                    loading={isLoading}
                    initialSort={'RBDMSIDString'}
                    initialSortDesc={false}
                    canClick={false}
                />
            </div>
        )

    } else {
        return null
    }
})