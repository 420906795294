import React, { useContext } from 'react'
import { AppStateContext } from '../AppStateContext'
import { FaTimes } from 'react-icons/fa'

import PanelHeader from '../../../elem/panel/PanelHeader'

export default () => {
    const { setCompareMenuVisible } = useContext(AppStateContext)

    return (
        <PanelHeader extraClass="wellDetailPanelHeader comparePanelHeader">
            <div className="panelHeaderText">Compare</div>
            <div className="panelButtons">
                <div
                    className="panelButton"
                    onClick={e => {
                        e.preventDefault()
                        setCompareMenuVisible(false)
                    }}
                >
                    <FaTimes />
                </div>
            </div>
        </PanelHeader>
    )
}
