import React from 'react'

import WellExplorer from './wells/Explorer'
import SampleExplorer from './samples/Explorer'
import PressureVolumeExplorer from './pressureVolume/Explorer'
import Map from './map/Map'
import WellDetail from './wellDetail/Detail'
import CompareMenu from './compare/CompareMenu'
import Aggregate from './aggregate/Aggregate'
import PressureAggregate from './pressureVolume/pressureAggregate/Aggregate'

export default () => (
    <div className="spaWrapper">
        <WellDetail />
        <CompareMenu />
        <div className="columns spaContainer is-full is-multiline is-1 is-variable">
            <div className="column is-one-third-desktop is-full-tablet is-full-mobile">
                <SampleExplorer width={'one-third'} />
                <Aggregate width={'one-third'} />
                <PressureVolumeExplorer /> 
                <PressureAggregate width={'one-third'} />
            </div> 
            <div className="column is-two-thirds-desktop is-full-tablet is-full-mobile">
                <Map width={'full'} />
                <WellExplorer width={'full'} />
            </div>
        </div>
    </div>
)
