import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useMemo,
    useCallback,
} from 'react'

import withConfig from '../../../../wrappers/withConfig'
import { AppStateContext } from '../../AppStateContext'
import { ParameterContext } from '../../../../wrappers/ParameterContext'
import { APIRequestContext } from '../../../../wrappers/APIRequestContext'
import { timeWindowOptions } from '../../../../../utils/chart/timeWindow'
import toast from '../../../../elem/Toast'
import { generatePressureDateParams } from '../../../../../utils/chart/values'
import { filterMultiSeriesTimeData, /*filterMultiSeriesTimePeriod*/ } from '../../../../../utils/chart/timeWindow'

const DataContext = createContext(null)

export default withConfig(({ config, children }) => {
    const { mapState } = useContext(AppStateContext)
    const { params } = useContext(ParameterContext)
    const { authenticatedFetch } = useContext(APIRequestContext)
    const [chartData, setChartData] = useState([])
    const [tooManyFeatures, setTooManyFeatures] = useState(false)
    const [timeWindow, setTimeWindow] = useState(timeWindowOptions[0])
    const [displayTimeWindowDropdown, toggleTimeWindowDropdown] = useState(false)
    const [zoomTrigger, setZoomTrigger] = useState(false)
    
    const [measureList, setMeasureList] = useState([])
    const [selectedMeasures, setSelectedMeasures] = useState([])
    const [displayMeasureWindowDropdown, toggleMeasureWindowDropdown] = useState(false)
    
    const [groupingList, setGroupingList] = useState(null)
    const [selectedGrouping, setSelectedGrouping] = useState(null)
    const [displayGroupingWindowDropdown, toggleGroupingWindowDropdown] = useState(false)

    const [selectedUnits, setSelectedUnits] = useState(null)
    const [resetExpanded, toggleResetExpanded] = useState(false)

    const startDateParams = useMemo(() => {
        const {monitorReqStartDate} = generatePressureDateParams(params)
        const d = [monitorReqStartDate.startDate, monitorReqStartDate.endDate]
        return d.some(x => x) ? d : null
    }, [params])

    const endDateParams = useMemo(() => {
        const {monitorReqEndDate} = generatePressureDateParams(params)
        const d = [monitorReqEndDate.startDate, monitorReqEndDate.endDate]
        return d.some(x => x) ? d : null
    }, [params])

    const [loading, setLoading] = useState(false)
    const { selectedFeatures } = mapState
    const { API_URL } = config

    const [isCollapsed, setCollapsed] = useState(false)

    const fetchGroupingList = useCallback(() => {
        return new Promise(async (resolve, reject) => {

            // get the ids of the selected features
            const facilityIDs = encodeURI(
                selectedFeatures.map(x => x.get('FacilityID')).toString()
            )
            
            // construct body of post request
            const body = JSON.stringify({
                facilityIDs: facilityIDs,
            })

            await authenticatedFetch(`${API_URL}/pressure/aggregate/getGroupingList`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers':
                        'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
                },
                body,
            })
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => resolve(response.groupings))
            .catch(e => {
                toast({
                    level: 'error',
                    message:
                        'Pressure Aggregate Chart: ' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server. Please try again later.'),
                })
                setLoading(false)
                return reject()
            })
        })
    }, [selectedFeatures, params])

    const fetchMeasureList = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            // get the ids of the selected features
            const facilityIDs = encodeURI(
                selectedFeatures.map(x => x.get('FacilityID')).toString()
            )
            const { monitorReqStartDate, monitorReqEndDate } = generatePressureDateParams(params)
            
            // construct body of post request
            const body = JSON.stringify({
                facilityIDs: facilityIDs,
                grouping: selectedGrouping ? selectedGrouping.Code : null,
                monitorReqStartDate,
                monitorReqEndDate,
            })

            await authenticatedFetch(`${API_URL}/pressure/aggregate/getMeasureList`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers':
                        'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
                },
                body,
            })
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                setMeasureList(response.measures)
                setSelectedMeasures([response.measures[0]])
                return resolve(response.measures)
            })
            .catch(e => {
                toast({
                    level: 'error',
                    message:
                        'Pressure Aggregate Chart: ' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server. Please try again later.'),
                })
                setLoading(false)
                return reject()
            })
        })
    }, [selectedFeatures, params, selectedGrouping])

    const fetchChartData = useCallback((measureList) => {
        return new Promise(async (resolve, reject) => {
            setLoading(true)
            const { monitorReqStartDate, monitorReqEndDate } = generatePressureDateParams(params)
            const facilityIDs = encodeURI(
                [...new Set(selectedFeatures.map(x => x.get('FacilityID')))].toString()
            )
            const measureTypes = encodeURI(measureList.map(x => x ? x.MeasureType : '').toString())
            const body = JSON.stringify({
                facilityIDs,
                measureTypes,
                grouping: selectedGrouping ? selectedGrouping.Code : null,
                monitorReqStartDate,
                monitorReqEndDate,
            })
            await authenticatedFetch(`${API_URL}/pressure/aggregate`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers':
                        'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
                },
                body,
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then(response => {
                    setChartData(response.chartData)
                    return resolve(response.chartData)
                })
                .catch(e => {
                    toast({
                        level: 'error',
                        message:
                        'Pressure Aggregate Chart: ' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server. Please try again later.'),
                        })
                    return reject()
                })
        })
    }, [selectedFeatures, selectedMeasures, selectedGrouping])
     

    // when the selected features change change the selected grouping which in turn changes the selected measures which in turn changes the chart data 
    useEffect(() => {
        if (selectedFeatures.length) {
            // SQL Server has a limit of 2100 parameters that can be supplied
            // to a query. if there is a workaround, this check can be removed
            if (selectedFeatures.length < 2100) {
                setLoading(true)
                setTooManyFeatures(false)
                fetchGroupingList()
                .then(async groupings => {
                    if (groupings && groupings.length) {
                        setGroupingList(groupings)
                        setSelectedGrouping(groupings && groupings.length ? groupings[0] : null)
                    }
                    else { //if no groupings were found then there's no need to continue, set chart data to nothing and stop loading
                        setChartData([])
                        setMeasureList([])
                        setSelectedMeasures([])
                        setLoading(false)
                    } 
                })
            } else {
                setTooManyFeatures(true)
            }
        } else {
            // if there are no features,
            // set data to an empty array
            // setAllToEmpty()
        }
    }, [selectedFeatures, startDateParams, endDateParams])

    useEffect(() => {
        if (selectedGrouping) {
            fetchMeasureList()
                .then(async measureList => {   
                    // updateSelectedMeasures(measureList)
                })
        }
    }, [selectedGrouping])

    // when the selected measures change, go fetch the new chart data
    useEffect(() => {
        if (!selectedMeasures.length) {
            setChartData([])
            return
        }
        fetchChartData(selectedMeasures)
            .then(() => setLoading(false))
            .catch(e => setLoading(false))
    }, [selectedMeasures])

    // const updateSelectedMeasures = useCallback(measureList => {
    //     if (
    //         selectedMeasures.length && 
    //         selectedMeasures.every(x => measureList.map(y => y.MeasureType).includes(x.MeasureType))
    //     ) {
    //         return
    //     } else {
    //         // otherwise, update the analyte list with the first entry in the list
    //         if (measureList.length) {
    //             const firstMeasure = measureList[0]
    //             setSelectedMeasures([firstMeasure])
    //         }
    //         else 
    //             setSelectedMeasures([])
    //     }
    // }, [selectedMeasures])

    const measureChartData = useMemo(() => {
        if (chartData && chartData.length) {
            return chartData
                ? chartData
                : filterMultiSeriesTimeData(
                      chartData,
                      timeWindow,
                      'DateString'
                  )
        }
        return []
    }, [chartData, startDateParams, endDateParams])

    const toggleSelectedMeasures = useCallback(
        measure => {
            if (selectedMeasures.find(x => x.MeasureType === measure.MeasureType && x.MeasureUnit === measure.MeasureUnit)) {
                setSelectedMeasures(
                    selectedMeasures.filter(x => x.MeasureType !== measure.MeasureType)
                )
            } else {
                if (selectedMeasures.length < 5) {
                    const unit = measure.MeasureUnitDisplay
                    if (selectedUnits && unit !== selectedUnits) {
                        toast({
                            level: 'info',
                            message: `Please select a measure that has the same units (${selectedUnits}) as the other measures in the chart.`,
                            alert: true,
                        })
                    } else {
                        setSelectedMeasures(selectedMeasures.concat(measure))
                    }
                } else {
                    toast({
                        level: 'info',
                        message:
                            'Too many measures selected. Select up to 5 measures to display.',
                        alert: true,
                    })
                }
            }
        },
        [selectedMeasures, selectedUnits]
    )

    useEffect(() => {
        if (selectedMeasures.length) {
            setSelectedUnits(selectedMeasures[0].MeasureUnitDisplay)
        } else {
            setSelectedUnits(null)
        }
    }, [selectedMeasures])

    const resetMeasures = useCallback(() => {
        const firstMeasure = measureList[0]
        if (firstMeasure) {
            setSelectedMeasures([firstMeasure])
        } else {
            setSelectedMeasures([])
        }
    }, [measureList])

    const resetZoom = useCallback(() => {
        setZoomTrigger(!zoomTrigger)
    }, [zoomTrigger])
    
    // const setAllToEmpty = useCallback(() => {
        // setChartData([])
        // setMeasureList([])
        // setSelectedMeasures([])
        // setGroupingList([])
        // setSelectedGrouping(null)
    // }, [chartData, measureList, selectedMeasures, groupingList, selectedGrouping])

    return (
        <DataContext.Provider
            value={{
                loading,
                chartData,
                tooManyFeatures,
                timeWindow,
                setTimeWindow,
                displayTimeWindowDropdown,
                toggleTimeWindowDropdown,
                toggleMeasureWindowDropdown,
                displayMeasureWindowDropdown,
                selectedUnits,
                resetZoom,
                zoomTrigger,
                isCollapsed,
                setCollapsed,
                resetExpanded,
                toggleResetExpanded,
                displayGroupingWindowDropdown,
                toggleGroupingWindowDropdown,
                groupingList,
                selectedGrouping,
                setSelectedGrouping,
                measureChartData,
                measureList,
                selectedMeasures,
                setSelectedMeasures,
                toggleSelectedMeasures,
                resetMeasures,
            }}
        >
            {children}
        </DataContext.Provider>
    )
})

export { DataContext }
