import React, { useContext } from 'react'
import { DataContext } from './DataContext'
import { PanelStyleContext } from '../../../elem/panel/PanelStyleContext'

const DetailTabs = () => {
    const { visibleTab, setVisibleTab } = useContext(DataContext)
    const { tabClass } = useContext(PanelStyleContext)
    return (
        <div className={`tabs is-small is-boxed is-fullwidth ${tabClass}`}>
            <ul>
                <li
                    className={visibleTab === 'well' ? 'is-active' : ''}
                    onClick={() => setVisibleTab('well')}
                >
                    <div>
                        <span>Sampling Point Details</span>
                    </div>
                </li>
                <li
                    className={visibleTab === 'project' ? 'is-active' : ''}
                    onClick={() => setVisibleTab('project')}
                >
                    <div>
                        <span>Project Information</span>
                    </div>
                </li>
                <li
                    className={visibleTab === 'samples' ? 'is-active' : ''}
                    onClick={() => setVisibleTab('samples')}
                >
                    <div>
                        <span>Sample Results</span>
                    </div>
                </li>
                <li
                    className={visibleTab === 'pressure' ? 'is-active' : ''}
                    onClick={() => setVisibleTab('pressure')}
                >
                    <div>
                        <span>Pressure-Volume</span>
                    </div>
                </li>
                <li
                    className={visibleTab === 'mit' ? 'is-active' : ''}
                    onClick={() => setVisibleTab('mit')}
                >
                    <div>
                        <span>MIT</span>
                    </div>
                </li>
                <li
                    className={visibleTab === 'screenCasing' ? 'is-active' : ''}
                    onClick={() => setVisibleTab('screenCasing')}
                >
                    <div>
                        <span>Screen-Casing</span>
                    </div>
                </li>
                <li
                    className={visibleTab === 'zone' ? 'is-active' : ''}
                    onClick={() => setVisibleTab('zone')}
                >
                    <div>
                        <span>Zones</span>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default DetailTabs
