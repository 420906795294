import React, { useContext } from 'react'

import { DataContext } from './DataContext'
import NoDataComponent from '../../../elem/table/client/NoDataComponent'
import TableContent from '../../../elem/table/client/TableContent'
import parseColumns from '../../../../utils/table/parseColumns'
import withConfig from '../../../wrappers/withConfig'

export default withConfig(({ config }) => {
    const { detailData, filterData, visibleTab, isLoading } = useContext(DataContext)
    const { ID_COLUMN, EXPLORER_LINK_COLUMN } = config
    if (
            detailData &&
            detailData.zone &&
            filterData &&
            filterData.zone &&
            visibleTab === 'zone'
    ) {
        const formFields = filterData.zone
        const zoneDetailsData = detailData.zone
        const columns = parseColumns(zoneDetailsData, formFields, EXPLORER_LINK_COLUMN, ID_COLUMN)
        return (
            <div className="is-size-7 detailTableWrapper zoneDetailWrapper">
                <NoDataComponent data={zoneDetailsData} isLoading={isLoading} />
                <TableContent
                    name="zoneDetails"
                    columns={columns}
                    data={zoneDetailsData}
                    loading={isLoading}
                    initialSort={null}
                    initialSortDesc={false}
                />
            </div>
        )

    } else {
        return null
    }
})