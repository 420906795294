import React, { useContext } from 'react'

import { DataContext } from './DataContext'
import NoDataComponent from '../../../elem/table/client/NoDataComponent'
import TableContent from '../../../elem/table/client/TableContent'
import parseColumns from '../../../../utils/table/parseColumns'
import withConfig from '../../../wrappers/withConfig'

export default withConfig(({ config }) => {
    const { detailData, filterData, visibleTab, isLoading } = useContext(DataContext)
    const { ID_COLUMN, EXPLORER_LINK_COLUMN } = config
    if (
            detailData &&
            detailData.screenCasing &&
            filterData &&
            filterData.screenCasing &&
            visibleTab === 'screenCasing'
    ) {
        const formFields = filterData.screenCasing
        const screenCasingDetailsData = detailData.screenCasing
        const columns = parseColumns(screenCasingDetailsData, formFields, EXPLORER_LINK_COLUMN, ID_COLUMN)
        return (
            <div className="is-size-7 sampleDetailWrapper">
                <NoDataComponent data={screenCasingDetailsData} isLoading={isLoading} />
                <TableContent
                    name="screenCasingDetails"
                    columns={columns}
                    data={screenCasingDetailsData}
                    containerClass={'detailTableWrapper'}
                    loading={isLoading}
                    initialSort={'FacilityID'}
                    initialSortDesc={true}
                />
            </div>
        )

    } else {
        return null
    }
})