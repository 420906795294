import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useCallback,
    useMemo,
} from 'react'

import toast from '../../../elem/Toast'
import withConfig from '../../../wrappers/withConfig'
import { AppStateContext } from '../AppStateContext'
import { getDataWithinTimePeriodWithOperator, timeWindowOptions } from '../../../../utils/chart/timeWindow'
import { ParameterContext } from '../../../wrappers/ParameterContext'
import { generateDateParams, generatePressureDateParams } from '../../../../utils/chart/values'
import { filterTimeData, getDataWithinTimePeriod } from '../../../../utils/chart/timeWindow'
import Toast from '../../../elem/Toast'

const DataContext = createContext(null)

const DataContextProvider = ({ config, children }) => {
    // get the detail id from the app state context
    const { mapState: { selectedFeatures }, compareMenuVisible, setCompareMenuVisible } = useContext(AppStateContext)
    const { params } = useContext(ParameterContext)

    // get the API_URL from config
    const { API_URL } = config

    // loading state
    const [isLoading, setLoading] = useState(false)
    const [isLoadingChart, setLoadingChart] = useState(false)

    // data state
    const [compareListData, setCompareListData] = useState([])
    const [configData, setConfigData] = useState([])
    const [visibleChartTab, setVisibleChartTab] = useState('sampleResult')

    const facilityIDsToCompare = useMemo(() =>
        compareMenuVisible && selectedFeatures && selectedFeatures.map(x => x.get('FacilityID'))
    , [selectedFeatures, compareMenuVisible])

    // display state
    const [timeWindow, setTimeWindow] = useState(timeWindowOptions[0])
    const [displayTimeWindowDropdown, toggleTimeWindowDropdown] = useState(false)
    
    const [chartProps, setChartProps] = useState({})
    const [selectedAnalytes, setSelectedAnalytes] = useState([])
    const [displayAnalyteWindowDropdown, toggleAnalyteWindowDropdown] = useState(false)
    const [selectedUnits, setSelectedUnits] = useState(null)

    const [zoomTrigger, setZoomTrigger] = useState(false)
    const [measureResetExpanded, toggleMeasureResetExpanded] = useState(false)
    const [analyteResetExpanded, toggleAnalyteResetExpanded] = useState(false)
    const [displayMeasureWindowDropdown, toggleMeasureWindowDropdown] = useState(false)
    const [displayGroupingWindowDropdown, toggleGroupingWindowDropdown] = useState(false)

    const [selectedMeasures, setSelectedMeasures] = useState([])
    const [selectedMeasureUnits, setSelectedMeasureUnits] = useState(null)
    
    const [selectedGrouping, setSelectedGrouping] = useState()

    const dateParams = useMemo(() => {
        const d = generateDateParams(params)
        return d.some(x => x !== null) ? d : null
    }, [params])

    const monitorReqStartDateParam = useMemo(() => { 
        const {monitorReqStartDate} = generatePressureDateParams(params)
        return monitorReqStartDate.startDate ? monitorReqStartDate : null
    }, [params])

    const monitorReqEndDateParam = useMemo(() => { 
        const {monitorReqEndDate} = generatePressureDateParams(params)
        return monitorReqEndDate.startDate ? monitorReqEndDate : null
    }, [params])

    useEffect(() => {
        if (facilityIDsToCompare) {
            if (facilityIDsToCompare.length < 1) {
                toast({
                    level: 'error',
                    message: `Select up to 5 sampling points to display Compare menu.`,
                    alert: true,
                })
                setCompareMenuVisible(false)
            } else if (facilityIDsToCompare.length > 5) {
                toast({
                    level: 'error',
                    message: 'Compare menu cannot contain more than 5 sampling points. Select up to 5 sampling points to display Compare menu.', 
                    alert: true,
                })
                setCompareMenuVisible(false)
            } else {
                fetchCompareChartData()
                fetchCompareFacilities()
            }
        }
    }, [facilityIDsToCompare])

    // fetch data on facilityID change
    const fetchCompareFacilities = useCallback(() => {
            setLoading(true)
            fetch(`${API_URL}/well/compare`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers':
                        'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
                },
                body: JSON.stringify(facilityIDsToCompare)
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then(response => {
                    setCompareListData(response.data)
                    setConfigData(response.configs)
                })
                .catch(e => {
                    toast({
                        level: 'error',
                        message:
                            'Well Detail: ' +
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server. Please try again later.'),
                    })
                })
                .finally(() => setLoading(false))
    }, [facilityIDsToCompare])
    
    const fetchCompareChartData = useCallback(() => {
        setLoadingChart(true)
        fetch(`${API_URL}/well/compareChartData`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers':
                    'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
            },
            body: JSON.stringify(facilityIDsToCompare)
        })
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                setChartProps(response)
            })
            .catch(e => {
                toast({
                    level: 'error',
                    message:
                        'Well Compare: ' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server. Please try again later.'),
                })
            })
            .finally(() => setLoadingChart(false))
    }, [facilityIDsToCompare])

    const analyteChartData = useMemo(() => {
        if (chartProps.analyteData && chartProps.analyteData.length) {
            return dateParams
                ? getDataWithinTimePeriod(
                    chartProps.analyteData,
                    dateParams,
                    'SampleDate'
                )
                : filterTimeData(
                    chartProps.analyteData,
                    timeWindow,
                    'SampleDate'
                )
        }
        return []
    }, [chartProps.analyteData, timeWindow, dateParams])

    const analyteList = useMemo(() => {
        if (analyteChartData && analyteChartData.length && chartProps && chartProps.analyteChartDropdown) {
            const activeAnalytes = chartProps.analyteChartDropdown.filter((e) =>
                analyteChartData.findIndex(a => a.ParamID === e.ParamID) !== -1
            )
            return activeAnalytes
        }
        return []
    }, [analyteChartData, chartProps])
        
    useEffect(() => {
        if (
            analyteList &&
            analyteList.length
        ) {
            setSelectedAnalytes([analyteList[0]])
        }
    }, [analyteList])

    useEffect(() => {
        if (selectedAnalytes.length) {
            setSelectedUnits(selectedAnalytes[0].Units)
        } else {
            setSelectedUnits(null)
        }
    }, [selectedAnalytes])
    
    const toggleSelectedAnalyte = useCallback(
        analyte => {
            if (selectedAnalytes.find(x => x.ParamID === analyte.ParamID)) {
                setSelectedAnalytes(
                    selectedAnalytes.filter(x => x.ParamID !== analyte.ParamID)
                )
            } else if (selectedAnalytes.length < 1) {
                    const unit = analyte.Units
                    if (selectedUnits && unit !== selectedUnits) {
                        toast({
                            level: 'info',
                            message: `Please select an analyte that has the same units (${selectedUnits}) as the other analytes in the chart.`,
                            alert: true,
                        })
                    } else {
                        setSelectedAnalytes(selectedAnalytes.concat(analyte))
                    }
            } else {
                setSelectedAnalytes([analyte])
            }
        }, [selectedAnalytes, selectedUnits]
    )

    const pressureChartDataSet = useMemo(() => {
        if (chartProps.pressureChartData && chartProps.pressureChartData.length) {
            let chartData = chartProps.pressureChartData.filter(x => !!x.MeasureDate)
            if (
                chartData && chartData.length
                && (monitorReqStartDateParam || monitorReqEndDateParam)
            ) {
                if (monitorReqStartDateParam) {
                    chartData = getDataWithinTimePeriodWithOperator(
                        chartData,
                        monitorReqStartDateParam,
                        'MonitorReqStartDate'
                    )
                }
                if (monitorReqEndDateParam) {
                    chartData = getDataWithinTimePeriodWithOperator(
                        chartData,
                        monitorReqEndDateParam,
                        'MonitorReqEndDate'
                    )   
                }
            } else {
                chartData = filterTimeData(
                    chartData,
                    timeWindow,
                    'MeasureDate'
                ) 
            }
            return chartData
        }
        return []
    }, [chartProps, timeWindow, monitorReqStartDateParam, monitorReqEndDateParam])

    const groupingList = useMemo(() => {
        if (chartProps && chartProps.pressureGroupingListDetail && chartProps.pressureGroupingListDetail.length) {
            setSelectedGrouping(chartProps.pressureGroupingListDetail[0])
            return chartProps.pressureGroupingListDetail.filter((e) =>
                pressureChartDataSet.findIndex(a => a.Grouping === e.Code) !== -1
            )
        }
        else 
            return []
    }, [chartProps.pressureGroupingListDetail, pressureChartDataSet])
    
    const measureList = useMemo(() => 
        pressureChartDataSet && selectedGrouping 
            ? chartProps.pressureMeasureListDetail.filter(x => x.Grouping === selectedGrouping.Code)
            .filter((e) =>
                pressureChartDataSet.findIndex(a => a.MeasureType === e.MeasureType) !== -1
            ) : chartProps.pressureMeasureListDetail
        , [chartProps.pressureMeasureListDetail, selectedGrouping])

    useEffect(() => {
        if (
            pressureChartDataSet &&
            pressureChartDataSet.length
        ) {
            // const firstItem = measureList.find(x => x.MeasureType === pressureChartDataSet[0].MeasureType && x.MeasureUnit === pressureChartDataSet[0].MeasureUnit)
            setSelectedMeasures([measureList[0]])
        }
    }, [pressureChartDataSet, measureList])
    
    useEffect(() => {
        if (selectedMeasures.length) {
            setSelectedMeasureUnits(selectedMeasures[0])
        } else {
            setSelectedMeasureUnits(null)
        }
    }, [selectedMeasures])

    const toggleSelectedMeasures = useCallback(
        measure => {
            if (selectedMeasures.find(x => x.MeasureType === measure.MeasureType && x.MeasureUnit === measure.MeasureUnit)) {
                setSelectedMeasures(
                    selectedMeasures.filter(x => x.MeasureType !== measure.MeasureType)
                )
            } else if (selectedMeasures.length < 1) {
                const unit = measure.MeasureUnit
                const selectedUnit = selectedMeasureUnits && selectedMeasureUnits.MeasureUnit ? selectedMeasureUnits.MeasureUnit : null
                if (selectedUnit && unit !== selectedUnit) {
                    toast({
                        level: 'info',
                        message: `Please select a measure that has the same units (${selectedMeasureUnits.MeasureUnitDisplay}) as the other measures in the chart.`,
                        alert: true,
                    })
                } else {
                    setSelectedMeasures(selectedMeasures.concat(measure))
                }
            } else {
                setSelectedMeasures([measure])

            }
        },
        [selectedMeasures, selectedMeasureUnits]
    )
    
    const resetZoom = useCallback(() => setZoomTrigger(!zoomTrigger), [zoomTrigger])
    
    return (
        <DataContext.Provider
            value={{
                compareListData,
                configData,
                isLoading,
                selectedAnalytes, 
                setSelectedAnalytes,
                displayAnalyteWindowDropdown, 
                toggleAnalyteWindowDropdown,
                selectedUnits, 
                setSelectedUnits,
                zoomTrigger, 
                setZoomTrigger,
                measureResetExpanded, 
                toggleMeasureResetExpanded,
                analyteResetExpanded, 
                toggleAnalyteResetExpanded,
                displayMeasureWindowDropdown, 
                toggleMeasureWindowDropdown,
                displayGroupingWindowDropdown, 
                toggleGroupingWindowDropdown,
                selectedMeasures, 
                setSelectedMeasures,
                selectedMeasureUnits,
                setSelectedMeasureUnits,
                selectedGrouping,
                setSelectedGrouping,
                timeWindow, 
                setTimeWindow,
                displayTimeWindowDropdown, 
                toggleTimeWindowDropdown,
                visibleChartTab, 
                setVisibleChartTab,
                analyteChartData,
                analyteList,
                toggleSelectedAnalyte,
                resetZoom,
                isLoadingChart,
                displayMeasureWindowDropdown,
                toggleMeasureWindowDropdown,
                displayGroupingWindowDropdown,
                toggleGroupingWindowDropdown,
                groupingList,
                selectedGrouping,
                setSelectedGrouping,
                pressureChartDataSet,
                measureList,
                selectedMeasures,
                setSelectedMeasures,
                toggleSelectedMeasures,
                selectedMeasureUnits,
            }}
        >
            {children}
        </DataContext.Provider>
    )
}

export { DataContext }
export default withConfig(DataContextProvider)
