import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useCallback,
    useMemo,
} from 'react'

import toast from '../../../elem/Toast'
import withConfig from '../../../wrappers/withConfig'
import { AppStateContext } from '../AppStateContext'
import { getDataWithinTimePeriodWithOperator, timeWindowOptions } from '../../../../utils/chart/timeWindow'
import { ParameterContext } from '../../../wrappers/ParameterContext'
import { generateDateParams, generatePressureDateParams } from '../../../../utils/chart/values'
import { filterTimeData, getDataWithinTimePeriod } from '../../../../utils/chart/timeWindow'

const DataContext = createContext(null)

const DataContextProvider = ({ config, children }) => {
    // get the detail id from the app state context
    const { detailState } = useContext(AppStateContext)
    const { params } = useContext(ParameterContext)
    const { well: wellDetailState } = detailState
    const facilityID = wellDetailState.facilityID

    // get the API_URL from config
    const { API_URL } = config

    // loading state
    const [isLoading, setLoading] = useState(false)

    // data state
    const [detailData, setDetailData] = useState({})
    const [filterData, setFilterData] = useState({})
    const [visibleTab, setVisibleTab] = useState('well')
    const [visibleChartTab, setVisibleChartTab] = useState('sampleResult')

    // display state
    const [timeWindow, setTimeWindow] = useState(timeWindowOptions[0])
    const [displayTimeWindowDropdown, toggleTimeWindowDropdown] = useState(
        false
    )
    const [selectedAnalytes, setSelectedAnalytes] = useState([])
    const [displayAnalyteWindowDropdown, toggleAnalyteWindowDropdown] = useState(false)
    const [selectedUnits, setSelectedUnits] = useState(null)

    const [zoomTrigger, setZoomTrigger] = useState(false)
    const [measureResetExpanded, toggleMeasureResetExpanded] = useState(false)
    const [analyteResetExpanded, toggleAnalyteResetExpanded] = useState(false)
    const [displayMeasureWindowDropdown, toggleMeasureWindowDropdown] = useState(false)
    const [displayGroupingWindowDropdown, toggleGroupingWindowDropdown] = useState(false)

    const [selectedMeasures, setSelectedMeasures] = useState([])
    const [selectedMeasureUnits, setSelectedMeasureUnits] = useState(null)
    
    const [selectedGrouping, setSelectedGrouping] = useState()

    const dateParams = useMemo(() => {
        const d = generateDateParams(params)
        return d.some(x => x !== null) ? d : null
    }, [params])

    const monitorReqStartDateParam = useMemo(() => { 
        const {monitorReqStartDate} = generatePressureDateParams(params)
        return monitorReqStartDate.startDate ? monitorReqStartDate : null
    }, [params])

    const monitorReqEndDateParam = useMemo(() => { 
        const {monitorReqEndDate} = generatePressureDateParams(params)
        return monitorReqEndDate.startDate ? monitorReqEndDate : null
    }, [params])

    // fetch data on facilityID change
    const fetchData = () => {
        if (facilityID) {
            fetch(`${API_URL}/well/${facilityID}`)
                .then(async response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then(response => {
                    setDetailData(response.data)
                    setFilterData(response.filterData)
                })
                .catch(e => {
                    toast({
                        level: 'error',
                        message:
                            'Well Detail: ' +
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server. Please try again later.'),
                    })
                })
                .finally(() => setLoading(false))
        }
    }

    useEffect(() => {
        fetchData(facilityID)
    }, [facilityID])

    const analyteChartData = useMemo(() => {
        if (detailData.analyteChart && detailData.analyteChart.length) {
            return dateParams
                ? getDataWithinTimePeriod(
                      detailData.analyteChart,
                      dateParams,
                      'SampleDate'
                  )
                : filterTimeData(
                      detailData.analyteChart,
                      timeWindow,
                      'SampleDate'
                  )
        }
        return []
    }, [detailData, timeWindow, dateParams])

    useEffect(() => {
        if (
            analyteChartData &&
            analyteChartData.length
        ) {
            const firstItem = analyteChartData[0]
            setSelectedAnalytes([firstItem])
        }
    }, [analyteChartData])
    
    const analyteList = useMemo(() => {
        if (analyteChartData && analyteChartData.length && detailData && detailData.analyteChartDropdown) {
            // const activeAnalytes = [...new Set(analyteChartData.map(x => x.Analyte))].map(x => detailData.analyteChartDropdown.find(y => y.Analyte === x))
            const activeAnalytes = detailData.analyteChartDropdown.filter((e) =>
                analyteChartData.findIndex(a => a.ParamID === e.ParamID) !== -1
            )
            return activeAnalytes
        }
        return []
    }, [analyteChartData, detailData])
    
    useEffect(() => {
        if (
            analyteList &&
            analyteList.length
        ) {
            const firstAnalyte = analyteList[0]
            setSelectedAnalytes([firstAnalyte])
        }
    }, [analyteList])

    useEffect(() => {
        if (selectedAnalytes.length) {
            setSelectedUnits(selectedAnalytes[0].Units)
        } else {
            setSelectedUnits(null)
        }
    }, [selectedAnalytes])
    
    const toggleSelectedAnalyte = useCallback(
        analyte => {
            if (selectedAnalytes.find(x => x.ParamID === analyte.ParamID)) {
                setSelectedAnalytes(
                    selectedAnalytes.filter(x => x.ParamID !== analyte.ParamID)
                )
            } else {
                if (selectedAnalytes.length < 5) {
                    const unit = analyte.Units
                    if (selectedUnits && unit !== selectedUnits) {
                        toast({
                            level: 'info',
                            message: `Please select an analyte that has the same units (${selectedUnits}) as the other analytes in the chart.`,
                            alert: true,
                        })
                    } else {
                        setSelectedAnalytes(selectedAnalytes.concat(analyte))
                    }
                } else {
                    toast({
                        level: 'info',
                        message:
                            'Too many analytes selected. Select up to 5 analytes to display.',
                        alert: true,
                    })
                }
            }
        }, [selectedAnalytes, selectedUnits]
    )

    const resetZoom = useCallback(() => setZoomTrigger(!zoomTrigger), [zoomTrigger])

    const pressureChartDataSet = useMemo(() => {
        if (detailData.pressureChartData && detailData.pressureChartData.length) {
            let chartData = detailData.pressureChartData.filter(x => !!x.MeasureDate)
            if (
                chartData && chartData.length
                && (monitorReqStartDateParam || monitorReqEndDateParam)
            ) {
                if (monitorReqStartDateParam) {
                    chartData = getDataWithinTimePeriodWithOperator(
                        chartData,
                        monitorReqStartDateParam,
                        'MonitorReqStartDate'
                    )
                }
                if (monitorReqEndDateParam) {
                    chartData = getDataWithinTimePeriodWithOperator(
                        chartData,
                        monitorReqEndDateParam,
                        'MonitorReqEndDate'
                    )   
                }
            } else {
                chartData = filterTimeData(
                    chartData,
                    timeWindow,
                    'MeasureDate'
                ) 
            }
            return chartData
        }
        return []
    }, [detailData, timeWindow, monitorReqStartDateParam, monitorReqEndDateParam])

    const groupingList = useMemo(() => {
        if (detailData && detailData.pressureGroupingListDetail && detailData.pressureGroupingListDetail.length) {
            setSelectedGrouping(detailData.pressureGroupingListDetail[0])
            return detailData.pressureGroupingListDetail.filter((e) =>
                pressureChartDataSet.findIndex(a => a.Grouping === e.Code) !== -1
            )
        }
        else 
            return []
    }, [detailData.pressureGroupingListDetail, pressureChartDataSet])
    
    const measureList = useMemo(() => 
        pressureChartDataSet && selectedGrouping 
            ? detailData.pressureMeasureListDetail.filter(x => x.Grouping === selectedGrouping.Code)
            .filter((e) =>
                pressureChartDataSet.findIndex(a => a.MeasureType === e.MeasureType) !== -1
            ) : detailData.pressureMeasureListDetail
        , [detailData, selectedGrouping, pressureChartDataSet])

    useEffect(() => {
        if (
            pressureChartDataSet &&
            pressureChartDataSet.length
        ) {
            // const firstItem = measureList.find(x => x.MeasureType === pressureChartDataSet[0].MeasureType && x.MeasureUnit === pressureChartDataSet[0].MeasureUnit)
            setSelectedMeasures([measureList[0]])
        }
    }, [pressureChartDataSet, measureList])
    
    useEffect(() => {
        if (selectedMeasures.length) {
            setSelectedMeasureUnits(selectedMeasures[0])
        } else {
            setSelectedMeasureUnits(null)
        }
    }, [selectedMeasures])

    const toggleSelectedMeasures = useCallback(
        measure => {
            if (selectedMeasures.find(x => x.MeasureType === measure.MeasureType && x.MeasureUnit === measure.MeasureUnit)) {
                setSelectedMeasures(
                    selectedMeasures.filter(x => x.MeasureType !== measure.MeasureType)
                )
            } else {
                if (selectedMeasures.length < 5) {
                    const unit = measure.MeasureUnit
                    const selectedUnit = selectedMeasureUnits && selectedMeasureUnits.MeasureUnit ? selectedMeasureUnits.MeasureUnit : null
                    if (selectedUnit && unit !== selectedUnit) {
                        toast({
                            level: 'info',
                            message: `Please select a measure that has the same units (${selectedMeasureUnits.MeasureUnitDisplay}) as the other measures in the chart.`,
                            alert: true,
                        })
                    } else {
                        setSelectedMeasures(selectedMeasures.concat(measure))
                    }
                } else {
                    toast({
                        level: 'info',
                        message:
                            'Too many measures selected. Select up to 5 measures to display.',
                        alert: true,
                    })
                }
            }
        },
        [selectedMeasures, selectedMeasureUnits]
    )
    
    const resetAnalytes = useCallback(() => {
        const firstAnalyte = analyteList[0]
        if (firstAnalyte) {
            setSelectedAnalytes([firstAnalyte])
        } else {
            setSelectedAnalytes([])
        }
    }, [analyteList])

    const resetMeasures = useCallback(() => {
        const firstMeasure = measureList[0]
        if (firstMeasure) {
            setSelectedMeasures([firstMeasure])
        } else {
            setSelectedMeasures([])
        }
    }, [measureList])

    return (
        <DataContext.Provider
            value={{
                facilityID,
                isLoading,
                detailData,
                filterData,
                visibleTab,
                timeWindow,
                setTimeWindow,
                displayTimeWindowDropdown,
                toggleTimeWindowDropdown,
                selectedAnalytes,
                setSelectedAnalytes,
                toggleSelectedAnalyte,
                toggleAnalyteWindowDropdown,
                displayAnalyteWindowDropdown,
                setVisibleTab,
                selectedUnits,
                analyteChartData,
                analyteList,
                zoomTrigger,
                resetZoom,
                visibleChartTab,
                setVisibleChartTab,
                measureResetExpanded,
                toggleMeasureResetExpanded,
                displayMeasureWindowDropdown,
                toggleMeasureWindowDropdown,
                displayGroupingWindowDropdown,
                toggleGroupingWindowDropdown,
                groupingList,
                selectedGrouping,
                setSelectedGrouping,
                pressureChartDataSet,
                measureList,
                selectedMeasures,
                setSelectedMeasures,
                toggleSelectedMeasures,
                resetMeasures,
                selectedMeasureUnits,
                analyteResetExpanded, 
                toggleAnalyteResetExpanded,
                resetAnalytes,
            }}
        >
            {children}
        </DataContext.Provider>
    )
}

export { DataContext }
export default withConfig(DataContextProvider)
