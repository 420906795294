import React, { useContext, useState, useCallback } from 'react'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'

import { ParameterContext } from '../../wrappers/ParameterContext'
import { APIRequestContext } from '../../wrappers/APIRequestContext'
import { AppStateContext } from '../explorer/AppStateContext'
import { UserContext } from '../../wrappers/UserContext'
import { getQueryStringFromParams } from '../../../utils/params'
import toast from '../../elem/Toast'
import withConfig from '../../wrappers/withConfig'

export default withConfig(({ config }) => {
    const [exporting, setExporting] = useState(false)
    const { params } = useContext(ParameterContext)
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { mapState: { selectedFeatures, mapSelectionIds } } = useContext(AppStateContext)
    const { user } = useContext(UserContext)
    const { API_URL, DB_EXPORT_TITLE, ID_COLUMN } = config

    const exportFilterResults = useCallback(params => {
        setExporting(true)
        const selectedIds = encodeURI(selectedFeatures.map(x => x.get(ID_COLUMN)).toString())

        const paramString = getQueryStringFromParams(params, true)
        if (paramString === '') {
            toast({
                level: 'info',
                message:
                    'There are no filters applied. To download the full database, click the Download Database button.',
                alert: true,
            })
            setExporting(false)
            return
        }

        // check to see if there are too many records
        authenticatedFetch(`${API_URL}/well/count?${paramString}`, 
        {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers':
                    'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
            },
            body: JSON.stringify({ selectedIds, mapSelectionIds })
        })
        .then(async response => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then(async response => {
            const { meta } = response
            const { count } = meta
            if (count < 2100) {
                await authenticatedFetch(`${API_URL}/export?${paramString}`)
                    .then(async response => {
                        if (response.ok) {
                            return response.blob()
                        } else {
                            const error = await response.text()
                            throw new Error(error)
                        }
                    })
                    .then(data => {
                        const dateString = dayjs(Date.now()).format('MM-DD-YYYY')
                        saveAs(data, `${DB_EXPORT_TITLE}_Filtered_${dateString}.xlsx`)
                    })
                    .catch(e => {
                        toast({
                            level: 'error',
                            message:
                                'Export Failed: ' +
                                (e.message
                                    ? e.message
                                    : 'Please check filter parameters and try again.'),
                        })
                    })
            } else {
                toast({
                    level: 'info',
                    message:
                        'Exports cannot contain more than 2100 sampling points. If you wish to export more data, please click the Download Database button.',
                    alert: true,
                })
                setExporting(false)
            }
        })
        .catch(e => {
            toast({
                level: 'error',
                message:
                    'Export - Well Count: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
        .finally(() => setExporting(false))
        
    }, [user, selectedFeatures, mapSelectionIds, DB_EXPORT_TITLE, API_URL])

    const downloadDatabase = useCallback(() => {
        setExporting(true)
        authenticatedFetch(`${API_URL}/export/download`)
            .then(async response => {
                if (response.ok) {
                    return response.blob()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(data => {
                const dateString = dayjs(Date.now()).format('MM-DD-YYYY')
                saveAs(data, `${DB_EXPORT_TITLE}_Full_${dateString}.xlsx`)
            })
            .catch(e => {
                toast({
                    level: 'error',
                    message:
                        'Download Failed: ' +
                        (e.message
                            ? e.message
                            : 'Contact the system administrator to ensure the file is present on the server.'),
                })
            })
            .finally(() => setExporting(false))
    }, [DB_EXPORT_TITLE, API_URL])

    return (
        <>
            <button
                className={`button is-info is-small ${
                    exporting ? 'is-loading' : ''
                }`}
                onClick={() => exportFilterResults(params)}
                disabled={exporting}
            >
                Export Filter Results
                {/* { exporting ? "" : 'Export Filter Results'} */}
            </button>
            <button
                className="button is-info is-small"
                onClick={() => downloadDatabase()}
                disabled={exporting}
            >
                Download Database
            </button>
        </>
    )
})
