const wellThemeColor = 1
const detailThemeColor = 2
const mapThemeColor = 3
const sampleThemeColor = 4
const aggregateThemeColor = 5
const pressureThemeColor = 6
const compareThemeColor = 7

const wellPanel = (width) => ({
    panelClassName: `column is-${width}`,
    backgroundColorClass: `background-${wellThemeColor}`,
    colorClass: `color-${wellThemeColor}`,
    selectDropDownCaratClass: `carat-class-${wellThemeColor}`,
    clearInputFieldClass: `hover-color-${wellThemeColor}`,
    tooltipClass: `tooltip-${wellThemeColor}`,
    headerIconClass: `icon-color-${wellThemeColor}`,
    tabClass: `tabs-${wellThemeColor}`,
    borderClass: `border-${wellThemeColor}`
})

const detailPanel = (width) => ({
    panelClassName: `column is-full`,
    backgroundColorClass: `background-${detailThemeColor}`,
    colorClass: `color-${detailThemeColor}`,
    selectDropDownCaratClass: `carat-class-${detailThemeColor}`,
    clearInputFieldClass: `hover-color-${detailThemeColor}`,
    tooltipClass: `tooltip-${detailThemeColor}`,
    headerIconClass: `icon-color-${detailThemeColor}`,
    tabClass: `tabs-${detailThemeColor}`,
    borderClass: `border-${detailThemeColor}`
})

const mapPanel = (width) => ({
    panelClassName: `column is-${width}`,
    backgroundColorClass: `background-${mapThemeColor}`,
    colorClass: `color-${mapThemeColor}`,
    selectDropDownCaratClass: `carat-class-${mapThemeColor}`,
    clearInputFieldClass: `hover-color-${mapThemeColor}`,
    tooltipClass: `tooltip-${mapThemeColor}`,
    headerIconClass: `icon-color-${mapThemeColor}`,
    tabClass: `tabs-${mapThemeColor}`,
    borderClass: `border-${mapThemeColor}`
})

const samplePanel = (width) => ({
    panelClassName: `column ${width === 'full' ? 'is-full': ''}`,
    backgroundColorClass: `background-${sampleThemeColor}`,
    colorClass: `color-${sampleThemeColor}`,
    selectDropDownCaratClass: `carat-class-${sampleThemeColor}`,
    clearInputFieldClass: `hover-color-${sampleThemeColor}`,
    tooltipClass: `tooltip-${sampleThemeColor}`,
    headerIconClass: `icon-color-${sampleThemeColor}`,
    tabClass: `tabs-${sampleThemeColor}`,
    borderClass: `border-${sampleThemeColor}`
})

const aggregatePanel = (width) => ({
    panelClassName: `column ${width === 'full' ? 'is-full': ''}`,
    backgroundColorClass: `background-${aggregateThemeColor}`,
    colorClass: `color-${aggregateThemeColor}`,
    selectDropDownCaratClass: `carat-class-${aggregateThemeColor}`,
    clearInputFieldClass: `hover-color-${aggregateThemeColor}`,
    tooltipClass: `tooltip-${aggregateThemeColor}`,
    headerIconClass: `icon-color-${aggregateThemeColor}`,
    tabClass: `tabs-${aggregateThemeColor}`,
    borderClass: `border-${aggregateThemeColor}`
})

const pressurePanel = (width) => ({
    panelClassName: `column ${width === 'full' ? 'is-full': ''}`,
    backgroundColorClass: `background-${pressureThemeColor}`,
    colorClass: `color-${pressureThemeColor}`,
    selectDropDownCaratClass: `carat-class-${pressureThemeColor}`,
    clearInputFieldClass: `hover-color-${pressureThemeColor}`,
    tooltipClass: `tooltip-${pressureThemeColor}`,
    headerIconClass: `icon-color-${pressureThemeColor}`,
    tabClass: `tabs-${pressureThemeColor}`,
    borderClass: `border-${pressureThemeColor}`
}) 

const pressureAggregatePanel = (width) => ({
    panelClassName: `column ${width === 'full' ? 'is-full': ''}`,
    backgroundColorClass: `background-${pressureThemeColor}`,
    colorClass: `color-${pressureThemeColor}`,
    selectDropDownCaratClass: `carat-class-${pressureThemeColor}`,
    clearInputFieldClass: `hover-color-${pressureThemeColor}`,
    tooltipClass: `tooltip-${pressureThemeColor}`,
    headerIconClass: `icon-color-${pressureThemeColor}`,
    tabClass: `tabs-${pressureThemeColor}`,
    borderClass: `border-${pressureThemeColor}`
})

const comparePanel = (width) => ({
    panelClassName: `column is-full`,
    backgroundColorClass: `background-${compareThemeColor}`,
    colorClass: `color-${compareThemeColor}`,
    selectDropDownCaratClass: `carat-class-${compareThemeColor}`,
    clearInputFieldClass: `hover-color-${compareThemeColor}`,
    tooltipClass: `tooltip-${compareThemeColor}`,
    headerIconClass: `icon-color-${compareThemeColor}`,
    tabClass: `tabs-${compareThemeColor}`,
    borderClass: `border-${compareThemeColor}`
})


export { wellPanel, samplePanel, mapPanel, detailPanel, aggregatePanel, pressurePanel, pressureAggregatePanel, comparePanel }