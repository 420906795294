import React, {
    useContext,
    useState,
    useEffect,
    useMemo,
    useCallback,
} from 'react'
import dayjs from 'dayjs'
import {
    ComposedChart,
    Line,
    YAxis,
    XAxis,
    Tooltip,
    Label,
    CartesianGrid,
    ResponsiveContainer,
    ReferenceArea,
    Legend,
} from 'recharts'

import { DataContext } from '../DataContext'
import Message from '../../../../elem/chart/Message'
import ChartTooltip from '../../../../elem/chart/Tooltip'
import { dateToString } from '../../../../../utils/dateUtils'
import palette from '../../../../../utils/chart/palette'
import { getAxisYDomain } from '../../../../../utils/chart/slice'
import { roundToFourDigits } from '../../../../../utils/chart/values'
import { CustomLegend } from './DetailAnalyteChart'

const getMeasureTypeDisplayValue = (measureCode, measureList) => measureList.find(x => x.MeasureType === measureCode).MeasureTypeDisplay

const DetailPressureChart = () => {
    const {
        pressureChartDataSet,
        selectedMeasures,
        isLoading,
        selectedMeasureUnits,
        zoomTrigger,
        visibleChartTab,
        measureList,
        selectedGrouping,
    } = useContext(DataContext)
    
    const groupKey = 'MeasureType'
    const [data, setData] = useState([])

    const [left, setLeft] = useState('dataMin')
    const [right, setRight] = useState('dataMax')
    const [refAreaLeft, setRefAreaLeft] = useState('')
    const [refAreaRight, setRefAreaRight] = useState('')
    const [top, setTop] = useState('dataMax')
    const [bottom, setBottom] = useState('dataMin')

    useEffect(() => {
        zoomOut()
    }, [zoomTrigger])

    useEffect(() => {
        if (pressureChartDataSet) {
            const grouping = selectedGrouping && selectedGrouping.Code
            const da = pressureChartDataSet
                .filter(d => {
                    if (grouping) {
                        return !!selectedMeasures.find(x => x.MeasureType === d.MeasureType && x.MeasureUnit === d.MeasureUnit) && d.Grouping === grouping 
                    }
                    return !!selectedMeasures.find(x => x.MeasureType === d.MeasureType && x.MeasureUnit === d.MeasureUnit) 
                })
                .map(d => ({
                    ...d,
                    MeasureDate: dayjs(d.MeasureDate)
                        .toDate()
                        .getTime(),
                })) // convert sample date to unix time for x-axis range
            setData(da)
        }
    }, [pressureChartDataSet, selectedMeasures, selectedGrouping])

    // get the set of all unique values in the column specified by groupKey
    const uniqueGroups = useMemo(
        () =>
            data &&
            data.length &&
            [...new Set(data.map(x => x[groupKey]))].sort(),
        [data]
    )
    // aggregate line chart data using unique dates. eg
    // [{SampleDate: date1, Analyte: "one" , Concentration: 100}, {SampleDate: date1, Analyte: "two", Concentration: 200}]
    // turns into [{Date: date1, "1": 100, "2": 200}]
    const chartData = useMemo(
        () =>
            data &&
            data.length &&
            [...new Set(data.map(d => d.MeasureDate))].map(date => {
                const dateEntry = { MeasureDate: date }
                return data
                    .filter(x => x.MeasureDate === date) // get entries associated w/ the current date
                    .reduce((acc, curr) => {
                        const groupName = curr[groupKey]
                        const value = curr['Measurement']
                        return {
                            ...acc,
                            [groupName]: value,
                        }
                    }, dateEntry)
            }),
        [data]
    )

    const zoom = useCallback(() => {
        let RAL = refAreaLeft
        let RAR = refAreaRight
        if (RAL === RAR || RAR === '') {
            RAL = ''
            RAL = ''
            return
        }

        // xAxis domain
        if (RAL > RAR) {
            const temp = RAL
            RAL = RAR
            RAR = temp
        }

        // yAxis domain
        const leftAxisDomain = getAxisYDomain(
            RAL,
            RAR,
            x => !x.includes('MeasureDate'),
            0,
            chartData,
            'MeasureDate'
        )
        setBottom(leftAxisDomain[0])
        setTop(leftAxisDomain[1])
        setLeft(RAL)
        setRight(RAR)
        setRefAreaRight('')
        setRefAreaLeft('')
    }, [refAreaLeft, refAreaRight, chartData])

    const zoomOut = useCallback(() => {
        setRefAreaLeft('')
        setRefAreaRight('')
        setLeft('dataMin')
        setRight('dataMax')
        setTop('dataMax')
        setBottom('dataMin')
    }, [chartData])

    const Lines = useMemo(
        () =>
            uniqueGroups &&
            uniqueGroups.map((groupName, idx) => {
                return (
                <Line
                    type="monotone"
                    dot={{ r: 1 }}
                    key={`bar-${idx}`}
                    dataKey={`${groupName}`}
                    // stackId={'shared'}
                    connectNulls 
                    stroke={palette[idx % palette.length]}
                    unit={
                        selectedMeasureUnits &&  selectedMeasureUnits.MeasureUnitDisplay 
                        ? selectedMeasureUnits.MeasureUnitDisplay 
                        : `(units unknown)`
                    }
                    name={getMeasureTypeDisplayValue(groupName, measureList)}
                    strokeWidth={1}
                    activeDot={{ r: 5 }}
                    animationDuration={200}
                />
            )}),
        [uniqueGroups, data, selectedMeasureUnits]
    )

    const legendData = useMemo(
        () =>
            uniqueGroups &&
            uniqueGroups.map((groupName, idx) => {
                return ({
                color: palette[idx % palette.length],
                type: 'line',
                dataKey: groupName,
                value: getMeasureTypeDisplayValue(groupName, measureList),
            })}),
        [uniqueGroups]
    )

    // construct chart
    const yAxisLabel = `Measurement (${
        selectedMeasureUnits && selectedMeasureUnits.MeasureUnitDisplay ? selectedMeasureUnits.MeasureUnitDisplay : 'units unknown'
    })`
    const xAxisLabel = 'Measurement Date'

    // if loading || no data, return nothing
    if (isLoading || visibleChartTab !== 'pressure') {
        return null
    }

    if (!data.length
        || data.every(x => !x.MeasureDate)) {
        return <Message text={`No Chart Data Available`} />
    }
    
    return (
        <div className="chart">
            {/* <div className="chartTitle">{chartTitle}</div> */}
            <div className="chartWrapper">
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                        data={chartData}
                        margin={{
                            top: 10,
                            right: 50,
                            left: 12,
                            bottom: 15,
                        }}
                        onMouseDown={e => e && e.activeLabel && setRefAreaLeft(e.activeLabel)}
                        onMouseMove={e => e && e.activeLabel && refAreaLeft && setRefAreaRight(e.activeLabel)}
                        onMouseUp={() => zoom()}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            allowDataOverflow
                            type="number"
                            dataKey="MeasureDate"
                            domain={[left, right]}
                            tickFormatter={unixTime => dateToString(unixTime)}
                        >
                            <Label
                                value={xAxisLabel}
                                offset={-10}
                                position="insideBottom"
                                className="nitrateChartXAxisLabel"
                            />
                        </XAxis>
                        <YAxis
                            allowDataOverflow
                            type="number"
                            tickFormatter={v => roundToFourDigits(v)}
                            domain={[bottom, top]}
                        >
                            <Label
                                value={yAxisLabel}
                                angle={-90}
                                offset={20}
                                position="insideLeft"
                                className="compareChartYAxisLabel"
                            />
                        </YAxis>
                        <Tooltip content={<ChartTooltip stagger={10}/>} />
                        <Legend
                            content={
                                <CustomLegend legendPayload={legendData} />
                            }
                            verticalAlign="top"
                            wrapperStyle={{
                                top: 1,
                                left: 0,
                                alignItems: 'center',
                                width: '100%',
                            }}
                        />
                        {Lines}
                        {refAreaLeft && refAreaRight ? (
                            <ReferenceArea
                                x1={refAreaLeft}
                                x2={refAreaRight}
                                strokeOpacity={0.3}
                            />
                        ) : null}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

export default DetailPressureChart
